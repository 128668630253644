import React, { useState, useEffect } from 'react';
import { endpoint } from '../App';
import { DateTimeInput, ReferenceInput, AutocompleteInput, Form, Loading, useTheme } from 'react-admin';
import { Box, List, ListItem, Typography, Button } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
moment.locale('ru');

function getStartDefault() {
    return moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss');
}

function getEndDefault() {
    return moment().set({ hour: 20, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss');
}

const AnalyticsFilter = ({
    setFilters,
    defaultStartDate,
    defaultEndDate,
    handleSelectedManagerFilterChange,
    handleSelectedFunnelFilterChange,
    defaultFunnel,
    currentManagerFilter,
    incomeSourceFilter,
}) => {
    const handleApplyFilters = (values) => {
        if (values.startDate && values.endDate) {
            setFilters({ search_start_time: values.startDate, 
                         search_end_time: values.endDate,
                         requestIncomeSource_id: values.requestIncomeSource_id || undefined,
                         manager_account_id: values.manager_account_id || undefined });
        }
    };

    return (
        <Form className='filterForm' onSubmit={handleApplyFilters}>
            <Box display="flex" flexDirection="row" mb={1} mt={2} alignItems="center">
                <DateTimeInput label="Начало периода" source="startDate" defaultValue={defaultStartDate} style={{ marginRight: '1rem' }} />
                <DateTimeInput label="Окончание периода" source="endDate" defaultValue={defaultEndDate} style={{ marginRight: '1rem' }} />
                <ReferenceInput source="head_manager_account_id" reference="sales" filter={{ 'head_of_dep': true }}>
                    <AutocompleteInput label="Руководитель" onChange={handleSelectedManagerFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
                </ReferenceInput>
                <ReferenceInput source="funnel_id" reference="funnels" defaultValue={defaultFunnel}>
                    <AutocompleteInput label="Воронка" optionText="name" defaultValue={defaultFunnel} onChange={handleSelectedFunnelFilterChange} sx={{ marginLeft: '1rem' }} />
                </ReferenceInput>
                <ReferenceInput source="requestIncomeSource_id" reference="requestIncomeSources">
                    <AutocompleteInput 
                        label="Канал поступления" 
                        defaultValue={incomeSourceFilter} 
                        optionText={(choice) => `${choice.name}`} 
                        sx={{ marginLeft: '1rem', width: '15rem' }} 
                    />
                </ReferenceInput>
                <ReferenceInput source="manager_account_id" reference="sales" /*filter={{ sales_id_for_requests: localStorage.getItem('id') }}*/ sort={{ field: 'last_name', order: 'ASC' }}>
                    <AutocompleteInput 
                        label="Менеджер" 
                        optionText={(choice) => `${choice.first_name} ${choice.last_name}`} 
                        sx={{ marginLeft: '1rem' }} 
                    />
                </ReferenceInput>
                <Button type="submit" variant="contained" color="primary" style={{ marginLeft: '1rem' }}>
                    Показать за период
                </Button>
            </Box>
        </Form>
    );
};

const LeadAnalyticsPage = () => {
    const [theme, setTheme] = useTheme();
    const [managers, setManagers] = useState([]);
    const [funnels, setFunnels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({ search_start_time: getStartDefault(), search_end_time: getEndDefault() });
    const [currentManagerFilter, setCurrentManagerFilter] = useState(null);
    const [currentFunnelFilter, setCurrentFunnelFilter] = useState(null);
    const [incomeSourceFilter, setIncomeSourceFilter] = useState(null);

    // Use effect to fetch funnel data and set the default funnel filter
    useEffect(() => {
        fetchFunnelsAndSetDefaultFilter();
    }, []);

    const fetchFunnelsAndSetDefaultFilter = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const responseFunnels = await axios.get(`${endpoint}/funnels`, { headers: { Authorization: `${token}` } });

            // Set the first funnel as the default if no funnel is selected
            const defaultFunnel = responseFunnels.data.length > 0 ? responseFunnels.data[0].id : null;

            // Set default funnel before making the main data request
            setCurrentFunnelFilter(defaultFunnel);

            // Now fetch the lead data using the default funnel
            fetchData({ ...filters, funnel_id: defaultFunnel });
            setFunnels(responseFunnels.data);
        } catch (error) {
            console.error('Fetch failed:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async (filters) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${endpoint}/lead_analytics`, {
                params: { team_members_head_id: currentManagerFilter, funnel_id: currentFunnelFilter, ...filters },
                headers: { Authorization: `${token}` },
            });

            setManagers(response.data.sort((a, b) => b.requests.requestsCount - a.requests.requestsCount));
        } catch (error) {
            console.error('Fetch failed:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSetFilters = (filters) => {
        fetchData(filters);
    };

    const handleSelectedManagerFilterChange = (value) => setCurrentManagerFilter(value);
    const handleSelectedFunnelFilterChange = (value) => setCurrentFunnelFilter(value);

    return (
        <Box>
            <AnalyticsFilter 
                setFilters={handleSetFilters} 
                defaultStartDate={filters.search_start_time} 
                defaultEndDate={filters.search_end_time}
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                handleSelectedFunnelFilterChange={handleSelectedFunnelFilterChange}
                defaultFunnel={currentFunnelFilter} // Pass the default funnel filter
                currentManagerFilter={currentManagerFilter}
                incomeSourceFilter={incomeSourceFilter}
            />
            {loading || managers.length < 1 ? (
                <Loading />
            ) : (
                <List
                    sx={{
                        backgroundColor: theme == 'light' ? '#fff' : null,
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <ListItem
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: `150px repeat(${funnels.find(f => f.id === currentFunnelFilter)?.stages.length || 0}, minmax(100px, 1fr))`,
                            borderBottom: '1px solid lightgrey',
                            alignItems: 'center',
                            backgroundColor: theme == 'light' ? '#f5f5f5' : null,
                        }}
                    >
                        <Box sx={{ textAlign: 'center', padding: '0.5rem' }}>
                        <Typography variant="subtitle2" align="center" sx={{ fontWeight: 'bold' }}>
                            Всего заявок:
                        </Typography>
                        <Typography variant="caption" align="center" sx={{ fontWeight: 'bold' }}>
                            {managers[0].requestsTotal.requestsTotalCount} (100%)
                        </Typography>
                        </Box>
                        {currentFunnelFilter &&
                        funnels.find(f => f.id === currentFunnelFilter)?.stages.map(stage => {
                            const totalStageRequests = managers.reduce((sum, manager) => {
                                return sum + (Array.isArray(manager.requests.requests)
                                    ? manager.requests.requests.filter(req => req.funnel_id === currentFunnelFilter && req.stage === stage.id).length
                                    : 0);
                            }, 0);

                            const totalRequestsCount = managers[0]?.requestsTotal?.requestsTotalCount || 1; // Prevent division by 0
                            const stagePercentage = ((totalStageRequests / totalRequestsCount) * 100).toFixed(1);

                            return (
                                <Box key={stage.id} sx={{ textAlign: 'center', padding: '0.5rem' }}>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                        {stage.name}
                                    </Typography>
                                    <Box sx={{ borderBottom: `4px solid ${stage.color}`, marginBottom: '0.3rem' }}></Box>
                                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                        {totalStageRequests} ({stagePercentage}%)
                                    </Typography>
                                </Box>
                            );
                        })}
                    </ListItem>
            
                    {managers.map(manager => (
                        <ListItem
                            key={manager.id}
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: `150px repeat(${funnels.find(f => f.id === currentFunnelFilter)?.stages.length || 0}, minmax(100px, 1fr))`,
                                borderBottom: '1px solid lightgrey',
                                alignItems: 'center',
                                padding: '0.5rem',
                            }}
                        >
                            <Box sx={{ textAlign: 'center', padding: '0.5rem' }}>
                                <Typography variant="body2" align="center">{manager.name}</Typography>
                                <Typography variant="caption" color="textSecondary">{manager.requests.requestsCount} ({manager.requestsTotal.requestsTotalCount > 0
                                        ? ((manager.requests.requestsCount / manager.requestsTotal.requestsTotalCount) * 100).toFixed(1)
                                        : 0}%)</Typography>
                            </Box>
                            {currentFunnelFilter &&
                                funnels.find(f => f.id === currentFunnelFilter)?.stages.map(stage => {
                                    const stageRequests = Array.isArray(manager.requests.requests)
                                        ? manager.requests.requests.filter(req => req.funnel_id === currentFunnelFilter && req.stage === stage.id).length
                                        : 0;
            
                                    const percentage = manager.requestsTotal.requestsTotalCount > 0
                                        ? ((stageRequests / manager.requestsTotal.requestsTotalCount) * 100).toFixed(1)
                                        : 0;
            
                                    return (
                                        <Box
                                            key={stage.id}
                                            sx={{
                                                textAlign: 'center',
                                                padding: '0.5rem',
                                                borderLeft: '1px solid lightgrey',
                                            }}
                                        >
                                            <Typography variant="body2" sx={{ fontWeight: stageRequests > 0 ? 'bold' : 'normal', opacity: stageRequests == 0 ? '0.5' : '1' }} >{stageRequests}</Typography>
                                            <Typography variant="caption" color="textSecondary" sx={{ fontWeight: stageRequests > 0 ? 'bold' : 'normal', opacity: stageRequests == 0 ? '0.5' : '1'}}>
                                                ({percentage}%)
                                            </Typography>
                                        </Box>
                                    );
                                })}
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
};

export default LeadAnalyticsPage;
